<template>
  <div class="aboutMainContainer">
    <presentation />
  </div>
</template>

<script>
import presentation from "../components/about/presentation.vue";
export default {
  components: { presentation },
};
</script>

<style scoped>
</style>